import React from "react"
import { graphql } from "gatsby"

import { Row } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

import Layout from "../components/layout"


const PrivacyTemplate = ({ data, pageContext: { lang, currentPath } }) => console.log('') || (

  <Layout wpLang={lang} currentPath={currentPath} pageTitle={data.wordpressPage.yoast_title} pageMeta={data.wordpressPage.yoast_meta} featuredImagePath="above-min.png">
    <Row className="single-page">
      <div className="entry-content">
        <h1>Dagcoin Gift Card Privacy Policy</h1>
        <p>Dagcoin Gift Card collects personal data of clients in accordance with this privacy policy. Dagcoin Gift Card encourages Client’s to read this privacy policy carefully. Clients should buy Dagcoin gift cards only if they consent to the privacy policy set forth below.</p>
        <h4>Dagcoin Gift Card collects and processes personal data of:</h4>
        <ol>
          <li>Clients who proceed to buy Dagcoin gift cards.</li>
        </ol>
        <h4>The types of information Dagcoin Gift Card may collect are as follows:</h4>
        <ol>
          <li>Contact information, such as Client’s name, mailing address, telephone number, and email address.</li>
          <li>Financial information, such as bank account numbers, credit card numbers and virtual currency account information.</li>
          <li>Biographic or demographic information, such as Client’s date of birth, gender, Country and any other information Client choose to provide about Client.</li>
          <li>If Dagcoin Gift Card bans or suspends Client from use of the service, a record of that ban or suspension.</li>
          <li>Technical information, including the Internet protocol (IP) address used to connect Client’s computer to the Internet, Client’s login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform.</li>
          <li>Details of the transactions Client carry out when using Dagcoin’s Gift Card Services, including geographic location from which the transaction originates.</li>
          <li>Information Dagcoin Gift Card receives from other sources.</li>
        </ol>
        <h4>Dagcoin Gift Card may use Client’s information as follows:</h4>
        <ol>
          <li>Provide Dagcoin Gift Card Services and customer support.</li>
          <li>Process transactions and send notices about Client’s transactions.</li>
          <li>Resolve disputes, collect payments, and troubleshoot problems.</li>
          <li>Prevent potentially prohibited or illegal activities and enforce Dagcoin gift card’s user agreements.</li>
          <li>Personalize, measure and improve Dagcoin gift card Services.</li>
          <li>Deliver marketing and promotional offers on behalf of Dagcoin Gift Card via email.</li>
          <li>Link or combine Client’s personal information with information Dagcoin Gift Card obtains from others to help understand Client’s needs and provide Client with better service.</li>
          <li>Carry out any other purpose for which the information was collected, to the extent such purpose is necessarily contemplated by the collection of such information or as otherwise notified in the DagCoin Gift Card Services at the time of collection.</li>
        </ol>
        <h4>The method of collecting personal data:</h4>
        <ol>
          <li>Dagcoin Gift Card collects data on the names, date of birth, country, mailing address and e-mail addresses of Dagcoin Gift Card Clients during the process of registering for the service of buying a gift card.</li>
          <li>Dagcoin Gift Card may receive information about Client from third parties like business partners; sub-contractors in technical; advertising networks; analytics providers and search information providers to whom client has given his/her consent to share such information.</li>
        </ol>
        <h4>Disclosure of Client’s information:</h4>
        <p>Dagcoin Gift Card may share Client’s information with selected third parties including:</p>
        <ol>
          <li>Affiliates, business partners, suppliers and sub-contractors for the performance of any contract Dagcoin Gift Card enters into with them for providing services to Client.</li>
          <li>Analytics and search engine providers that assist us in the improvement and optimisation of Dagcoin Gift Card’s site.</li>
          <li>If Dagcoin Gift Card is under a duty to disclose or share Client’s personal data in order to comply with any legal obligation, or in order to enforce or apply Dagcoin Gift Card’s Customer Agreement and other applicable agreements; or to protect the rights, property, or safety of DagCoin Gift Card, DagCoin Gift Card’s customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.</li>
          <li>To assist Dagcoin Gift Card in conducting or co-operating in investigations of fraud or other illegal activity where Dagcoin Gift Card believes it is reasonable and appropriate to do so.</li>
          <li>To prevent and detect fraud or crime.</li>
        </ol>
        <h4>Cookies:</h4>
        <p>Dagcoin Gift Card Services use cookies to distinguish Client from other users. This helps Dagcoin Gift Card to provide Client with a good experience and also allows Dagcoin Gift Card to improve Services.</p>
        <h4>Client’s rights:</h4>
        <ol>
          <li>Client has the right to ask Dagcoin Gift Card not to process Client’s personal data for marketing purposes by contacting Dagcoin Gift Card at <a href="mailto:giftcard@dagcoin.org">giftcard@dagcoin.org</a></li>
          <li>Subject to applicable laws, Client may have the right to access information Dagcoin Gift Card has about the client. Client’s right of access can be exercised in accordance with the relevant data protection legislation. Any access request may be subject to a fee to meet DagCoin Gift Card’s costs in providing Client with details of the information DagCoin Gift Card holds about Client.</li>
        </ol>
        <h4>Term of retention of personal data:</h4>
        <p>Dagcoin Gift Card will store data for as long as it is necessary to provide products and services to Client and others, including those described above. Information associated with Client’s account will be kept until Client’s account is deleted, unless DagCoin Gift Card no longer need the data to provide products and services in which case the information may be deleted even before the Client’s account is deleted. Some information may be stored for legal purposes.</p>
      </div>
    </Row>
  </Layout>
)
export const query = graphql`
  query($lang: String) {
    wordpressPage(
      slug: {eq: "privacy"}
      wpml_current_locale: {eq: $lang}
    ) {
      title
      yoast_meta {
        name
        content
        property
      }
      yoast_title
        wpml_current_locale
    }



  }
`
export default PrivacyTemplate